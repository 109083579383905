import React, { useState } from 'react';
import '../Quiz/Quiz.css';
import dayjs from 'dayjs';

export const DateDropdown = ({ id, label, isrequired, onChange, error, setErrors, answers }) => {
  const orderSummaryItem = answers.find(item => item.id === id);
  let initialValue = orderSummaryItem ? orderSummaryItem.value : '';

  const today = new Date();
  const dates = [];

  // Generate dates starting from today up to 8 days from now
  for (let i = 0; i < 9; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() + i);
    dates.push(date);
  }

  // Match the initial value to one of the options
  if (!initialValue.includes('Today') && !initialValue.includes('Tomorrow')) {
    const initialDate = dayjs(initialValue, 'MM/DD/YYYY').toDate();
    dates.forEach((date, index) => {
      if (date.getDate() === initialDate.getDate() &&
        date.getMonth() === initialDate.getMonth() &&
        date.getFullYear() === initialDate.getFullYear()) {
        if (index === 0) {
          initialValue = 'Today';
        } else if (index === 1) {
          initialValue = 'Tomorrow';
        } else {
          const dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()];
          initialValue = `${dayOfWeek} - ${date.toDateString()}`;
        }
      }
    });
  }
  const [value, setValue] = useState(initialValue);
  const [warning, setWarning] = useState('');

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);

    let formattedDate;
    const today = new Date();

    if (newValue === 'Today') {
      formattedDate = dayjs(today).format('MM/DD/YYYY');
    } else if (newValue === 'Tomorrow') {
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      formattedDate = dayjs(tomorrow).format('MM/DD/YYYY');
    } else {
      formattedDate = dayjs(newValue.split(' - ')[1]).format('MM/DD/YYYY');
    }

    onChange(id, formattedDate);

    if (newValue.includes('Today') || newValue.includes('Tomorrow')) {
      setWarning('Warning: Short notice!');
    } else {
      setWarning('');
      setErrors(id, '');
    }
  };

  const handleBlur = (e) => {
    const date = e.target.value;
    if (isrequired && !date.trim()) {
      setErrors(id, 'Date is required');
    } else {
      setErrors(id, '');
    }
  };

  return (
    <div>
      <label>{label}</label>
      <br />
      <select
        className='form-control custom-select-container'
        onChange={handleChange}
        required={isrequired}
        onBlur={handleBlur}
        value={value}
      >
        <option className="custom-select-items" value='' hidden>Select...</option>
        {dates.map((date, index) => {
          let label = '';
          if (index === 0) {
            label = 'Today';
          } else if (index === 1) {
            label = 'Tomorrow';
          } else {
            const dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()];
            label = `${dayOfWeek} - ${date.toDateString()}`;
          }
          return (
            <option key={index} value={label} className={`custom-select-item ${value.includes(label) ? 'selected' : ''}`}>
              {label}
            </option>
          );
        })}
      </select>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {warning && <div style={{ color: 'orange' }}>{warning}</div>}
    </div>
  );
};