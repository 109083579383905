import React, { useMemo, useState } from 'react';
import '../Quiz/Quiz.css';


export const Dropdown = ({ id, label, isrequired, onChange, error, setErrors, answers, options, defaultOption }) => {
  const orderSummaryItem = answers.find(item => item.id === id);
  const initialValue = orderSummaryItem ? orderSummaryItem.value : '';
  const [value, setValue] = useState(initialValue || defaultOption);
  const [warning, setWarning] = useState('');

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(id, newValue);
  };

  const handleBlur = (e) => {
    const option = e.target.value;
    if (isrequired && !option.trim()) {
      setErrors(id, 'Field is required');
    } else {
      setErrors(id, '');
      onChange(id, option);
    }
  };

  const isHeadTable = useMemo(() => answers.find(item => item.id === 'input_head_table')?.value, [answers]);
  if (id === 'input_settings_number') {
    return (
      isHeadTable === 'Yes' ? (
        <div>
          <br />
          <label>{label}</label>
          <br />
          <select
            className='form-control'
            onChange={handleChange}
            required={isrequired}
            onBlur={handleBlur}
            value={value}
          >
            <option value='' hidden>Select...</option>
            {options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          {error && <div style={{ color: 'red' }}>{error}</div>}
        </div>
      ) : null
    );
  } else {
    return (
      <div>
        <br />
        <label>{label}</label>
        <br />
        <select
          className='form-control'
          onChange={handleChange}
          required={isrequired}
          onBlur={handleBlur}
          value={value}
        >
          <option value='' hidden>Select...</option>
          {options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
        {error && <div style={{ color: 'red' }}>{error}</div>}
      </div>
    );
  }

};