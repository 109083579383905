import React, { useEffect, useState } from 'react';
import '../Quiz/Quiz.css';

export const Upsell = ({ id, label, isrequired, onChange, error, setErrors, answers, options, }) => {
  const initialValues = answers.find(item => item.id === id)?.value || '';
  const [selectedValues, setSelectedValues] = useState(initialValues);

  useEffect(() => {
    setSelectedValues(initialValues);
  }, [initialValues]);

  const handleChange = (e, optionLabel) => {
    const checked = e.target.checked;
    let valuesArray = selectedValues ? selectedValues.split(',').map(item => item.trim()) : [];
    if (checked) {
      if (!valuesArray.includes(optionLabel)) {
        valuesArray.push(optionLabel);
      }
    } else {
      valuesArray = valuesArray.filter(value => value !== optionLabel);
    }
    const formattedLabels = valuesArray.join(', ');
    setSelectedValues(formattedLabels);
    onChange(id, formattedLabels);
    setErrors(id, '');

  };

  const handleBlur = (e) => {
    const optionLabel = e.target.value;

    if (isrequired && !optionLabel.trim()) {
      setErrors(id, 'Field is required');
    } else {
      setErrors(id, '');
      const valuesArray = selectedValues ? selectedValues.split(',').map(item => item.trim()) : [];
    }
  };

  return (
    <div>
      <br />
      <label>{label}</label>
      <br />
      <div className="grid-container">
        {options.map((option, index) => (
          <div className="checkbox-wrapper-16" key={index}>
            <label className="checkbox-wrapper">
              <input
                type="checkbox"
                className="checkbox-input"
                id={`${id}-${index}`}
                value={option.label}
                checked={selectedValues.split(',').map(item => item.trim()).includes(option.label)}
                onChange={(e) => handleChange(e, option.label)}
                onBlur={handleBlur}
                required={isrequired} />
              <span className="checkbox-tile">
                <span className="checkbox-title">{option.label}</span>
                <span className="checkbox-description">{option.description}</span>
                {option.price && <span className="checkbox-price">${option.price}{/*  per station */}</span>}
              </span>
            </label>
          </div>
        ))}
      </div>
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>

  );
};