import React from 'react';
import './Quiz.css';
import AdditionalInfo from '../components/AdditionalInfo';
import { TextInput } from '../components/TextInput';
import { PhoneInput } from '../components/PhoneInput';
import { EmailInput } from '../components/EmailInput';
import { DropdownPrice } from '../components/DropdownPrice';
import { DateDropdown } from '../components/DateDropdown';
import { Dropdown } from '../components/Dropdown';
import { NumberInput } from '../components/NumberInput';
import { AddressInput } from '../components/AddressInput';
import { RadioCheck } from '../components/RadioCheck';
import { TimeDropdown } from '../components/TimeDropdown';
import { Upsell } from '../components/Upsell';
import { UpsellDropdown } from '../components/UpsellDropdown';
import { PaymentInput } from '../components/PaymentInput';
import { NoteInput } from '../components/NoteInput';

const getComponent = (question, onChange, errors, setSpecificError, answers, updateHiddenQuestions) => {
  switch (question.type) {
    case 'text':
      return (
        <TextInput
          key={question.id}
          id={question.id}
          label={question.label}
          isrequired={question.isrequired}
          onChange={onChange}
          error={errors}
          setErrors={setSpecificError}
          answers={answers}
          hidden={question.isHidden}
          setHidden={updateHiddenQuestions}
          defaultTime={question?.default ?? null}
        />
      );
    case 'phone':
      return (
        <PhoneInput
          key={question.id}
          id={question.id}
          label={question.label}
          isrequired={question.isrequired}
          onChange={onChange}
          error={errors}
          setErrors={setSpecificError}
          answers={answers}
          hidden={question.isHidden}
          setHidden={updateHiddenQuestions}

        />
      );
    case 'email':
      return (
        <EmailInput
          key={question.id}
          id={question.id}
          label={question.label}
          onChange={onChange}
          error={errors}
          setErrors={setSpecificError}
          answers={answers}
          isrequired={question.isrequired}
          hidden={question.isHidden}
          setHidden={updateHiddenQuestions}
        />
      );
    case 'dateDropdown':
      return (
        <DateDropdown
          key={question.id}
          id={question.id}
          label={question.label}
          isrequired={question.isrequired}
          onChange={onChange}
          error={errors}
          setErrors={setSpecificError}
          answers={answers}
          options={question.options}
        />
      );
    case 'dropdownPrice':
      return (
        <DropdownPrice
          key={question.id}
          id={question.id}
          label={question.label}
          isrequired={question.isrequired}
          onChange={onChange}
          error={errors}
          setErrors={setSpecificError}
          answers={answers}
          options={question.options}
          defaultOption={question.default ?? null}
        />
      );

    case 'dropdown':
      return (
        <Dropdown
          key={question.id}
          id={question.id}
          label={question.label}
          isrequired={question.isrequired}
          onChange={onChange}
          error={errors}
          setErrors={setSpecificError}
          answers={answers}
          options={question.options}
          defaultOption={question.default ?? null}
        />
      );
    case 'number':
      return (
        <NumberInput
          key={question.id}
          id={question.id}
          label={question.label}
          isrequired={question.isrequired}
          onChange={onChange}
          error={errors}
          setErrors={setSpecificError}
          answers={answers}
        />
      );
    case 'address':
      return (
        <AddressInput
          key={question.id}
          id={question.id}
          label={question.label}
          onChange={onChange}
          error={errors}
          setErrors={setSpecificError}
          answers={answers}
          isrequired={question.isrequired}
        />
      );
    case 'radio':
      return (
        <RadioCheck
          key={question.id}
          id={question.id}
          label={question.label}
          options={question.options}
          isrequired={question.isrequired}
          onChange={onChange}
          error={errors}
          setErrors={setSpecificError}
          answers={answers}
        />
      );
    case 'note':
      return (
        <NoteInput
          key={question.id}
          id={question.id}
          label={question.label}
          isrequired={question.isrequired}
          onChange={onChange}
          error={errors}
          setErrors={setSpecificError}
          answers={answers}
        />
      );
    case 'time':
      return (
        <TimeDropdown
          key={question.id}
          id={question.id}
          label={question.label}
          isrequired={question.isrequired}
          onChange={onChange}
          error={errors}
          setErrors={setSpecificError}
          answers={answers}
          defaultTime={question.default}
        />
      );
    case 'upsell':
      return (
        <Upsell
          key={question.id}
          id={question.id}
          label={question.label}
          options={question.options}
          isrequired={question.isrequired}
          onChange={onChange}
          error={errors}
          setErrors={setSpecificError}
          answers={answers}
        />
      );
    case 'upsellDropdown':
      return (
        <UpsellDropdown
          key={question.id}
          id={question.id}
          label={question.label}
          options={question.options}
          isrequired={question.isrequired}
          onChange={onChange}
          error={errors}
          setErrors={setSpecificError}
          answers={answers}
        />
      );
    case 'additional_info':
      return (
        <AdditionalInfo
          key={question.id}
          id={question.id}
          label={question.label}
          isrequired={question.isrequired}
          onChange={onChange}
          error={errors}
          setErrors={setSpecificError}
          answers={answers}
        />
      );
    case 'payment':
      return (
        <PaymentInput
          key={question.id}
          id={question.id}
          label={question.label}
          onChange={onChange}
          error={errors}
          setErrors={setSpecificError}
          answers={answers}
          isrequired={question.isrequired}
        />
      );
    case 'emailBody':
      return (
        <div>
          <h1>Submitted!</h1>
          <p>Thank you
            <strong> {answers.find(item => item.id === 'input_name')?.value} </strong>
            for your order - we will review your order and reply with our confirmation.
          </p>
        </div>
      );
    default:
      return null;
  }
};

export default getComponent;
