import React, { useRef, useState } from 'react';
import '../Quiz/Quiz.css';
import { Margin, usePDF } from 'react-to-pdf';

const AdditionalInfo = ({ id, label, isrequired, onChange, error, setErrors, answers }) => {
  const orderSummaryItem = answers.find(item => item.id === id);
  const initialValue = orderSummaryItem ? orderSummaryItem.value : '';
  const [value, setValue] = useState(initialValue);


  const pdfRef = useRef();

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(id, newValue);
  };
  const handleBlur = (e) => {
    const text = e.target.value;
    setErrors(id, '');
    onChange(id, text);
  };

  const { toPDF, targetRef } = usePDF({
    method: 'save',
    filename: "order_summary.pdf",
    page: { margin: Margin.MEDIUM },
  });

  // const orderSummaryDetails = answers.map(({ label, value }) => `${label}: ${value}`).join('\n');

  //TODO: get the terms and conditions URL
  const downloadFile = () => {
    // Assuming termsAndConditions is a URL pointing to the PDF file
    const downloadUrl = 'terms_and_conditions.pdf';
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'terms_and_conditions.pdf'; // Set the desired file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <div>
      <div style={{ marginTop: '20px' }}>
        <div></div>
        <i
          title='Download terms and conditions'
          role="button"
          onClick={downloadFile}
          className="mdi mdi-file-check-outline"
          style={{ cursor: 'pointer', fontSize: '24px', marginRight: '10px', color: '#D4DD26' }}

        ></i>
        <i
          title='Download order summary'
          role="button"
          onClick={toPDF}
          className="mdi mdi-file-document-outline"
          style={{ cursor: 'pointer', fontSize: '24px', color: '#D4DD26' }}
        ></i>
      </div>
      <div className="form-group">
        <label >{label}</label>
        <textarea
          className="form-control"
          rows="3"
          type="text"
          value={value}
          onChange={handleChange}
          isrequired={isrequired}
          onBlur={handleBlur}
        ></textarea>
      </div>
      {error && <div style={{ color: 'red' }}>{error}</div>}

      <div style={{ position: 'absolute', left: '-1000px' }}>
        <div
          ref={targetRef}
        >
          <h1>Order Summary</h1>
          {answers.map(({ id, label, value }) => value?.length > 0 && (
            <div>
              <span><strong>{label}: {''}</strong></span>
              {value.includes(',') ? (
                <ul>
                  {value.split(',').map((val, i) => (
                    <li>{val}</li>
                  ))}
                </ul>
              ) : (
                <span className="order-value">{value}</span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdditionalInfo;