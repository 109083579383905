import React, { useEffect, useState } from 'react';
import '../Quiz/Quiz.css';


export const PhoneInput = ({ id, label, isrequired, onChange, error, setErrors, answers, hidden, setHidden }) => {
  const orderSummaryItem = answers.find(item => item.id === id);
  const initialValue = orderSummaryItem ? orderSummaryItem.value : '';
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(id, newValue);
  };
  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phoneRegex.test(phoneNumber);
  };

  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  };

  const handleBlur = (e) => {
    const phoneNumber = e.target.value;
    if (isrequired && !phoneNumber.trim()) {
      setErrors(id, 'Phone number is required');
    } else if (phoneNumber && !validatePhoneNumber(phoneNumber)) {
      setErrors(id, 'Invalid phone number');
    } else {
      setErrors(id, '');
      onChange(id, formatPhoneNumber(phoneNumber));
    }
  };

  useEffect(() => {
    if (hidden) {
      onChange(id, '');
      setValue('');
    }
  }, [hidden]);

  return (
    <div>
      {id === 'input_manager_number' && hidden &&
        <div>
          <br />
          <label
            onClick={e => { setHidden(id, !hidden); }}
            role="button" style={{ cursor: 'pointer' }}
          >
            <i className='mdi mdi-plus-circle-outline' /> Additional Info</label>
        </div>
      }
      {!hidden ? (
        <>
          <br />
          <label>{label}</label>
          <br />
          <input
            className='form-control'
            type="tel"
            onChange={handleChange}
            onBlur={handleBlur}
            isrequired={isrequired}
            value={value}
            onInput={(e) => {
              e.target.value = formatPhoneNumber(e.target.value);
            }}
          />
          {error && <div style={{ color: 'red' }}>{error}</div>}
          <br />
        </>) : (null)}
    </div>
  );
};