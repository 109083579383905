import React, { useEffect, useState } from 'react';
import '../Quiz/Quiz.css';

export const UpsellDropdown = ({ id, label, isrequired, onChange, error, setErrors, answers, options, }) => {
  const initialValues = answers.find(item => item.id === id)?.value || '';
  const [selectedValues, setSelectedValues] = useState(initialValues);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(`#${id}`)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [id]);

  const handleChange = (optionLabel) => {
    let valuesArray = selectedValues ? selectedValues.split(',').map(item => item.trim()) : [];
    if (valuesArray.includes(optionLabel)) {
      valuesArray = valuesArray.filter(value => value !== optionLabel);
    } else {
      valuesArray.push(optionLabel);
    }
    const formattedLabels = valuesArray.join(', ');
    setSelectedValues(formattedLabels);
    onChange(id, formattedLabels);
    setErrors(id, '');

  };

  const handleBlur = () => {
    if (isrequired && !selectedValues.trim()) {
      setErrors(id, 'Field is required');
    } else {
      setErrors(id, '');
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div id={id}>
      <br />
      <label>{label}</label>
      <br />
      <div
        className="custom-select-container"
        tabIndex="0"
        onBlur={handleBlur}
      >
        <div className="custom-select" onClick={toggleDropdown}>
          <span>{selectedValues || 'Select an option'}</span>
        </div>
        {isOpen && (
          <div className="custom-select-items">
            {options.map((option, index) => (
              <div
                key={index}
                className={`custom-select-item ${selectedValues.includes(option.label) ? 'selected' : ''}`}
                onClick={() => handleChange(option.label)}
              >
                <div className="option-content">
                  <div className="option-label">
                    <strong>{option.label}</strong>
                  </div>
                  <div className="option-description">
                    {option.description}
                  </div>
                  {option.price && (
                    <div className="option-price">
                      ${option.price}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};