import React, { useEffect, useState } from 'react';
import '../Quiz/Quiz.css';


export const NumberInput = ({ id, label, isrequired, onChange, error, setErrors, answers }) => {
  const orderSummaryItem = answers.find(item => item.id === id);
  const initialValue = orderSummaryItem ? orderSummaryItem.value : '';
  const [value, setValue] = useState(initialValue);
  const [maxValue, setMaxValue] = useState(null);

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (maxValue !== null && Number(newValue) > maxValue) {
      setValue(maxValue);
    } else {
      setValue(newValue);
    }
    onChange(id, newValue);
  };

  const handleBlur = (e) => {
    const number = e.target.value;
    if (isrequired && !number.trim()) {
      setErrors(id, id === 'input_name' ? 'Name is required' : 'This field is required');
    } else {
      setErrors(id, '');
      onChange(id, number);
    }
  };

  useEffect(() => {
    if (id === 'input_men_number' || id === 'input_ladies_number') {
      let guestAmount = 0;
      const guestNumber = answers.find(item => item.id === 'input_guest_number')?.value;
      if (guestNumber) {
        guestAmount = parseInt(guestNumber, 10);
        const otherValue = parseInt(answers.find(item => item.id === (id === 'input_men_number' ? 'input_ladies_number' : 'input_men_number'))?.value || 0, 10);
        setMaxValue(guestAmount - otherValue);
      }
    }
  }, [id, answers]);

  return (
    <div>
      <label>{label}</label>
      <br />
      <input
        type="number"
        value={value}
        onChange={handleChange}
        isrequired={isrequired}
        onBlur={handleBlur}
        min={0}
        max={maxValue !== null ? maxValue : undefined}
        className='form-control'

      />
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};