import React, { useState } from 'react';
import '../Quiz/Quiz.css';

export const DropdownPrice = ({ id, label, isrequired, onChange, error, setErrors, answers, options, defaultOption }) => {
  const orderSummaryItem = answers.find(item => item.id === id);
  const initialValue = orderSummaryItem ? orderSummaryItem.value : '';
  const [value, setValue] = useState(initialValue || defaultOption);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(id, newValue);
  };

  const handleBlur = (e) => {
    const option = e.target.value;
    if (isrequired && !option.trim()) {
      setErrors(id, 'Field is required');
    }
  };

  return (
    <div>
      <br />

      <label>{label}</label>
      <br />
      <select
        className='form-control'
        onChange={handleChange}
        isrequired={isrequired}
        onBlur={handleBlur}
        value={value}
      >
        <option value='' hidden>Select...</option>
        {options.map((option, index) => {
          return (
            <option key={index} value={option.label}>
              {option.label}
            </option>
          );
        })}
      </select>
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};