import React, { useEffect, useRef, useState } from 'react';
import '../Quiz/Quiz.css';

export const AddressInput = ({ id, label, onChange, error, setErrors, answers }) => {
  const orderSummaryItem = answers.find(item => item.id === id);
  const initialValue = orderSummaryItem ? orderSummaryItem.value : '';
  const [street, city, state, zip, country] = initialValue.split(', ');

  const [warning, setWarning] = useState('');
  const autocompleteRef = useRef(null);

  const [inputValue, setInputValue] = useState(initialValue);
  const [address, setAddress] = useState(
    initialValue ?
      {
        street: street,
        city: city,
        state: state,
        zip: zip,
        country: country,
      } : {
        street: '',
        city: '',
        state: '',
        zip: '',
        country: ''
      });

  const allowedAreas = [
    'Monsey',
    'Spring Valley',
    'Chestnut Ridge',
    'Airmont',
    'Pomona',
    'Suffern',
    'Montebello',
    'Wesley Hills',
  ];

  const formatAddress = (addressObj) => {
    const { street, city, state, zip, country } = addressObj;
    return `${street}, ${city}, ${state} ${zip}, ${country}`;
  };

  useEffect(() => {
    const loadScript = (url) => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    if (!window.google) {
      loadScript(`https://maps.googleapis.com/maps/api/js?key=AIzaSyDRI5z3uFxKLHWdzmBcjFcWIqj5l3D3RC4&libraries=places`);
    }

    const handleScriptLoad = () => {
      const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current, {
        types: ['address'],
        componentRestrictions: { country: 'us' },
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }

        const addressComponents = place.address_components;
        const updatedAddress = {
          street: '',
          city: '',
          state: '',
          zip: '',
          country: '',
        };

        addressComponents.forEach((component) => {
          const types = component.types;
          if (types.includes('street_number')) {
            updatedAddress.street = `${component.long_name} ${updatedAddress.street}`;
          }
          if (types.includes('route')) {
            updatedAddress.street += component.long_name;
          }
          if (types.includes('locality')) {
            updatedAddress.city = component.long_name;
          }
          if (types.includes('administrative_area_level_1')) {
            updatedAddress.state = component.short_name;
          }
          if (types.includes('postal_code')) {
            updatedAddress.zip = component.long_name;
          }
          if (types.includes('country')) {
            updatedAddress.country = component.long_name;
          }
        });

        const isAllowedArea = allowedAreas.includes(updatedAddress.city);

        if (!isAllowedArea) {
          setWarning('Warning: We will contact you with additional costs for this location.');
        } else if (updatedAddress.city === ('New City' || 'Nanuet')) {
          setWarning('We may not service this area and we will contact you with additional costs for this location if any.');
        } else {
          setWarning('');
        }

        setAddress(updatedAddress);
        setInputValue(formatAddress(updatedAddress));
        onChange(id, formatAddress(updatedAddress));
      });
    };

    if (window.google) {
      handleScriptLoad();
    } else {
      window.addEventListener('load', handleScriptLoad);
    }

    return () => {
      window.removeEventListener('load', handleScriptLoad);
    };
  }, [id, onChange]);

  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setAddress({ ...address, street: value }); // Update the street part of the address
    onChange(id, value);
  };

  return (
    <div>
      <label>{label}</label>
      <div>
        <input
          type="text"
          ref={autocompleteRef}
          placeholder="Start typing your address"
          required
          style={{ width: '80%' }}
          className="form-control"
          value={inputValue}
          onChange={handleChange}
        />
      </div>
      <div>
        <input type="hidden" value={address.street} />
        <input type="hidden" value={address.city} />
        <input type="hidden" value={address.state} />
        <input type="hidden" value={address.zip} />
        <input type="hidden" value={address.country} />
      </div>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {warning && <div style={{ color: 'orange' }}>{warning}</div>}
      <br />
      <h5 style={{ fontSize: '0.8rem', marginTop: '10px', color: 'grey' }}>
        We offer catering services in Monsey, Spring Valley, Chestnut Ridge, Airmont, Pomona, Suffern, Montebello, Wesley Hills,
        certain areas in New City & certain areas in Nanuet.
      </h5>
      <h5 style={{ fontSize: '0.8rem', color: 'grey' }}>
        For out of area, please provide your address and we will contact you with the additional costs.
      </h5>
    </div>
  );
};
