import React, { useEffect, useState } from 'react';
import '../Quiz/Quiz.css';
import { Alert, Col, Form, InputGroup, Row } from 'react-bootstrap';
import FormInput from './FormInput';


export const PaymentInput = ({ id, label, onChange, error, setErrors, answers, isrequired }) => {
  const orderSummaryItem = answers.find(item => item.id === id);
  const initialValue = orderSummaryItem ? orderSummaryItem.value : {};
  const [newCardInfo, setNewCardInfo] = useState({
    cardName: '',
    brand: 'nocard',
    cardNumber: '',
    cvv: '',
    expMonth: 1,
    expYear: new Date().getFullYear().toString(),
    billingAddress: '',
    zipCode: '',
  });
  const [zelleInfo, setZelleInfo] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('card');

  const cardBrands = [
    {
      value: 'Visa',
      label: 'Visa',
      firstNumber: "4"
    },
    {
      value: 'Amex',
      label: 'American Express',
      firstNumber: "3"
    },
    {
      value: 'Mastercard',
      label: 'Mastercard',
      firstNumber: "5"
    },
    {
      value: 'Discover',
      label: 'Discover',
      firstNumber: "6"
    }
  ];
  //TODO: add ccbrand
  const getPaymentImage = (ccBrand) => {
    let ccBrandLowercase = ccBrand?.toLowerCase();
    if (ccBrandLowercase === 'amex') return './american-express.png';
    if (ccBrandLowercase === 'visa') return './visa.png';
    if (ccBrandLowercase === 'discover') return './discover.png';
    if (ccBrandLowercase === 'mastercard') return './master.png';
    else return './no-card.png';
  };

  const expYears = () => {
    const currentYear = new Date().getFullYear();
    let yearOptions = [];
    for (let i = 0; i < 10; i++) {
      yearOptions.push((currentYear + i).toString());
    }
    return yearOptions;
  };

  const handleCardChange = (updatedInfo) => {
    const updatedCardInfo = { ...newCardInfo, ...updatedInfo, method: 'card' };
    setNewCardInfo(updatedCardInfo);
    onChange(id, updatedCardInfo);
  };
  const validateCardInfo = (cardInfo) => {
    let errors = [];

    if (!cardInfo.cardNumber || cardInfo.cardNumber?.length < 13 || cardInfo.cardNumber?.length > 19) {
      errors.push('Invalid card number');
    }
    if (!cardInfo.cvv) {
      errors.push('Invalid CVV');
    }
    if (!cardInfo.cardName?.trim()) {
      errors.push('Card name is required');
    }
    if (!cardInfo.billingAddress?.trim()) {
      errors.push('Billing address is required');
    }
    if (!cardInfo.zipCode || cardInfo.zipCode?.length !== 5) {
      errors.push('Invalid zip code');
    }

    if (paymentMethod === 'card' && errors?.length > 0) {
      setErrors(id, errors.join('. '));
      return false;
    }

    setErrors(id, '');
    return true;
  };


  const validateZelleInfo = (zelleInfo) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;
    return emailRegex.test(zelleInfo) || phoneRegex.test(zelleInfo);
  };

  const handleZelleChange = (zelleInfo) => {
    setZelleInfo(zelleInfo);
    if (validateZelleInfo(zelleInfo)) {
      const phoneRegex = /^\d{10}$/;
      if (phoneRegex.test(zelleInfo)) {
        const formattedZelleInfo = zelleInfo.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        setZelleInfo(formattedZelleInfo);
        onChange(id, { zelle: formattedZelleInfo, method: 'zelle' });
      } else {
        onChange(id, { zelle: zelleInfo, method: 'zelle' });
      }
      setErrors(id, '');
    } else {
      setErrors(id, 'Please enter a valid email or phone number.');
    }
  };

  return (
    <div
      style={{ marginTop: '50px', position: 'relative' }}>

      <h4 > Add Payment Method </h4>
      <div>
        <form style={{ paddingBottom: '30px' }}>
          <Row>
            <Col>
              <Form.Check
                type="radio"
                label="Credit Card"
                name="paymentMethod"
                id="creditCard"
                checked={paymentMethod === 'card'}
                onChange={() => {
                  setPaymentMethod('card');
                  setErrors(id, '');
                  // onChange(id, newCardInfo);
                }}
              />
            </Col>
            <Col>
              <Form.Check
                type="radio"
                label="Zelle"
                name="paymentMethod"
                id="zelle"
                checked={paymentMethod === 'zelle'}
                onChange={() => {
                  setPaymentMethod('zelle');
                  setErrors(id, '');
                  // onChange(id, { zelle: '' });
                }}
              />
            </Col>
          </Row>
          {paymentMethod === 'card' ? (
            <>
              <Row>
                <Col xs={8} md={10} >
                  {/* <InputGroup> */}
                  <FormInput
                    type="masked"
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      ' ',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      ' ',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      ' ',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      newCardInfo.brand !== 'Amex' ? /[0-9]/ : '',
                    ]}
                    name="cardNumber"
                    placeholder="Card number"
                    floatingLabel="Card number"
                    guide={true}
                    onChange={(e) => {
                      let selected = cardBrands.find(c => c.firstNumber === e.target.value.charAt(0));
                      const updatedCardInfo = {
                        ...newCardInfo,
                        brand: selected?.value || "",
                        cardNumber: e.target.value.replace(/ /g, '').substring(0, selected?.value === 'Amex' ? 15 : 16)
                      };
                      setNewCardInfo(updatedCardInfo);
                      onChange(id, updatedCardInfo);
                    }
                    }
                    // className="form-control"
                    containerClass={'mb-2'}
                    value={newCardInfo.cardNumber}
                    required={isrequired}
                  // onBlur={(e) => { handleBlur(e, 'card number'); }}
                  />
                  {/* </InputGroup> */}
                </Col><Col xs={3} md={2}><div style={{ display: 'flex', minHeight: "58px" }} >
                  <img src={getPaymentImage(newCardInfo.brand)} alt="Card Brand" height={45}></img>
                </div>
                </Col>

              </Row>
              <Row>
                <Col xs={4}>
                  <FormInput
                    type="select"
                    name="month"
                    onChange={(e) => handleCardChange({ expMonth: Number(e.target.value) })}
                    value={newCardInfo.expMonth}
                    containerClass={'mb-2'}
                    placeholder="Month"
                    floatingLabel="Month"
                    required={isrequired}
                  // onBlur={(e) => { handleBlur(e, 'month'); }}
                  >
                    {[...Array(12).keys()].map(month => (
                      <option value={month + 1} key={month + 1}>{month + 1}</option>
                    ))}
                  </FormInput>
                </Col>
                <Col xs={4}>
                  <FormInput
                    type="select"
                    name="year"
                    onChange={(e) => handleCardChange({ expYear: e.target.value.toString() })}
                    value={newCardInfo.expYear}
                    placeholder="Year"
                    floatingLabel="Year"
                    required={isrequired}
                  // onBlur={(e) => { handleBlur(e, 'year'); }}
                  >
                    {expYears().map(year => <option value={year} key={year}>{year}</option>)}
                  </FormInput>
                </Col>
                <Col xs={4}>
                  <FormInput
                    type="password"
                    name="cvv"
                    placeholder="CVV"
                    floatingLabel="CVV"
                    onChange={(e) => handleCardChange({ cvv: e.target.value.replace(/\D/g, "") })}
                    value={newCardInfo.cvv}
                    required={isrequired}
                    // onBlur={(e) => { handleBlur(e, 'security code'); }}
                    maxLength="4"
                    minLength="3"
                    hidePasswordEye={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormInput
                    type="text"
                    name="cardName"
                    placeholder="Card name"
                    floatingLabel="Card name"
                    onChange={(e) => handleCardChange({ cardName: e.target.value })}
                    containerClass={'mb-2'}
                    value={newCardInfo.cardName}
                    required={isrequired}
                  // onBlur={(e) => { handleBlur(e, 'card name'); }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={8}>
                  <FormInput
                    type="text"
                    name="street"
                    placeholder="Address"
                    floatingLabel="Address"
                    onChange={(e) => handleCardChange({ billingAddress: e.target.value })}
                    containerClass={'mb-2'}
                    value={newCardInfo.billingAddress}
                    required={isrequired}
                  // onBlur={(e) => { handleBlur(e, 'adddress'); }}
                  />
                </Col>
                <Col xs={4}>
                  <FormInput
                    type="text"
                    name="zipCode"
                    placeholder="Zip Code"
                    floatingLabel="Zip Code"
                    onChange={(e) => handleCardChange({ zipCode: e.target.value })}
                    minLength='5'
                    maxLength='5'
                    containerClass={'mb-2'}
                    value={newCardInfo.zipCode}
                    required={isrequired}
                    onBlur={validateCardInfo}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col xs={12}>
                <br />
                <FormInput
                  type="text"
                  name="zelle"
                  placeholder="Email or Phone"
                  floatingLabel="Email or Phone"
                  onChange={(e) => handleZelleChange(e.target.value)}
                  containerClass={'mb-2'}
                  value={zelleInfo}
                />
              </Col>
            </Row>
          )}
        </form>
      </div>
      {error && (
        <div style={{ color: 'red', position: 'absolute', bottom: '-20px', left: '0', width: '100%', textAlign: 'center', marginTop: '20px' }}>
          {error}
        </div>
      )}
    </div >
  );
};