// App.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@mdi/font/css/materialdesignicons.min.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Quiz from './Quiz';
import SubmitPage from './Quiz/SubmitPage';

const App = () => {
  return (
    <Router>
      <div className='background background-image'>
        <div className='container border-container'>
          <Routes>
            <Route path="/submit" component={SubmitPage} />
          </Routes>
          <h1 className='center'>Book a simcha</h1>
          <Quiz />
        </div>
      </div>
    </Router>
  );
};

export default App;
