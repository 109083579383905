import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import OrderSummary from './OrderSummary';
import './Quiz.css';

const SubmitPage = () => {
  return (
    <div>
      <h1>Thank you for your order!</h1>
      <p>We will review your order and we will reply with our confirmation.</p>
      <p>Details of the order:</p>
      <OrderSummary />
      <p>If you have any questions or concerns, please contact us at <a href="https://www.havajavaonline.com/">havajavaonline.com</a></p>
    </div>
  );
};

export default SubmitPage;