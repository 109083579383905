import React, { useState } from 'react';
import '../Quiz/Quiz.css';

export const NoteInput = ({ id, label, isrequired, onChange, error, setErrors, answers }) => {
  const orderSummaryItem = answers.find(item => item.id === id);
  const initialValue = orderSummaryItem ? orderSummaryItem.value : '';
  const [value, setValue] = useState(initialValue);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(id, newValue);
  };

  const handleBlur = (e) => {
    const text = e.target.value;
    if (!text.trim()) {
      setIsExpanded(false);
    } else {
      setErrors(id, '');
      onChange(id, text);
    }
  };

  const handleFocus = () => {
    setIsExpanded(true);
  };

  return (
    <div>
      <br />
      <label onClick={(e) => setIsExpanded(true)}>{label}</label>
      <br />
      {isExpanded ? (
        <textarea
          value={value}
          onChange={handleChange}
          isrequired={isrequired}
          onBlur={handleBlur}
          onFocus={handleFocus}
          rows={5}
        />
      ) : (
        <input
          type="text"
          value={value}
          onChange={handleChange}
          isrequired={isrequired}
          onBlur={handleBlur}
          onFocus={handleFocus}
          hidden={!isExpanded}
          className='form-control'

        />
      )}
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};