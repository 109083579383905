import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@mdi/font/css/materialdesignicons.min.css';

const EmailBody = (answers, totalPrice) => {
  const name = answers?.find(item => item.id === 'input_name')?.value;
  const email = answers?.find(item => item.id === 'input_email')?.value;
  const phone = answers?.find(item => item.id === 'input_phone')?.value;
  const name2 = answers?.find(item => item.id === 'input_name2')?.value;
  const email2 = answers?.find(item => item.id === 'input_email2')?.value;
  const phone2 = answers?.find(item => item.id === 'input_phone2')?.value;
  const date = answers?.find(item => item.id === 'input_date')?.value;
  const time = answers?.find(item => item.id === 'input_simcha_time')?.value;
  const shacharisTime = answers?.find(item => item.id === 'input_shachris_time')?.value;
  const address = answers?.find(item => item.id === 'input_address')?.value;
  const daveningLocation = answers?.find(item => item.id === 'input_davening_location')?.value;
  const guestNumber = answers?.find(item => item.id === 'input_guest_number')?.value;
  const ladiesNumber = answers?.find(item => item.id === 'input_ladies_number')?.value;
  const menNumber = answers?.find(item => item.id === 'input_men_number')?.value;
  const tableNumber = answers?.find(item => item.id === 'input_table_number')?.value;
  const tableclothColor = answers?.find(item => item.id === 'input_tablecloth_color')?.value;
  const mechitza = answers?.find(item => item.id === 'input_mechitza')?.value === 'Yes' ? true : false;
  const headTable = answers?.find(item => item.id === 'input_head_table')?.value === 'Yes' ? true : false;
  const settingsNumber = answers?.find(item => item.id === 'input_settings_number')?.value;
  const upsellBig = answers?.find(item => item.id === 'input_upsell_big')?.value;
  const upsellSmall = answers?.find(item => item.id === 'input_upsell_small')?.value;
  const paymentInfo = answers?.find(item => item.id === 'input_payment')?.value;
  const cardNumber = paymentInfo?.cardNumber;
  const expMonth = paymentInfo?.expMonth;
  const expYear = paymentInfo?.expYear;
  const cvv = paymentInfo?.cvv;
  const billingAddress = paymentInfo?.billingAddress;
  const zipCode = paymentInfo?.zipCode;
  const total = totalPrice; // calculate total price
  const contactEmail = 'havajava@gmail.com'; // Update with actual contact email
  const managerNum = answers?.find(item => item.id === 'input_manager_number')?.value;
  const lock = answers?.find(item => item.id === 'input_door_code')?.value;



  let emailString = `Dear ${name},\n\n`;
  emailString += `Thank you for your order. We will review your order and reply with our confirmation shortly.\n\n`;

  emailString += `Details of the Order:\n\n`;
  emailString += `When: ${date} - ${time} - ${shacharisTime} shacharis\n`;
  emailString += `At: ${address}\n`;
  emailString += `${daveningLocation}\n`;
  emailString += `${guestNumber} guests (${ladiesNumber} ladies - ${menNumber} men)\n`;
  emailString += `${tableNumber} Tables\n\n`;

  emailString += mechitza ? 'Mechitza\n' : 'No mechitza\n';
  emailString += `Tablecloth Color: ${tableclothColor}\n\n`;

  emailString += headTable ? 'Head Table\n' : 'No Head Table\n';
  if (headTable) emailString += `Settings: ${settingsNumber}\n\n`;

  if (upsellBig) {
    emailString += `HJ Signature Stations\n`;
    emailString += `${upsellBig}\n\n`;
  }

  if (upsellSmall) {
    emailString += `Platters\n`;
    emailString += `${upsellSmall}\n\n`;
  }

  emailString += `Contact Information:\n\n`;
  emailString += `Email: ${email}\n`;
  emailString += `Phone: ${phone}\n\n`;

  if (name2 || email2 || phone2) {
    emailString += `Second Contact:\n\n`;
    if (name2) emailString += `Name: ${name2}\n`;
    if (email2) emailString += `Email: ${email2}\n`;
    if (phone2) emailString += `Phone: ${phone2}\n\n`;
  }

  emailString += `Total: $${total}\n\n`;

  if (paymentInfo?.method === 'card') {
    emailString += `Payment Information:\n\n`;
    emailString += `CC: ${cardNumber}\n`;
    emailString += `Exp: ${expMonth}/${expYear}\n`;
    emailString += `CVV: ${cvv}\n\n`;
    emailString += `Billing Address:\n`;
    emailString += `${billingAddress}, ${zipCode}\n\n`;
  } else if (paymentInfo?.method === 'zelle') {
    emailString += `Pay with Zelle: ${paymentInfo.zelle}\n\n`;
  }

  if (managerNum || lock) {
    emailString += `Note:\n\n`;
    if (managerNum) emailString += `Hall Manager's Number: ${managerNum}\n\n`;
    if (lock) emailString += `Lock Combination: ${lock}\n\n`;
  }

  emailString += `If you have any questions or concerns, please contact us at ${contactEmail}.\n\n`;

  emailString += `Best regards,\n`;
  emailString += `Hava Java\n`;

  return emailString;

};




export default EmailBody;




