// @flow
import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';
import MaskedInput from 'react-text-mask';
import '../Quiz/Quiz.css';


/* Password Input */
const PasswordInput = ({ name, placeholder, refCallback, errors, register, className, floatingLabel, hidePasswordEye, ...otherProps }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <InputGroup className="mb-0">
        {floatingLabel ? (
          <Form.Floating>
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              placeholder={placeholder}
              name={name}
              id={name}
              as="input"
              ref={(r) => {
                if (refCallback) refCallback(r);
              }}
              className={classNames(className, errors && errors[name] ? 'is-invalid' : '')}
              // isInvalid={errors && errors[name] ? true : false}
              {...(register ? register(name) : {})}
              autoComplete={name}
              {...otherProps}
            />
            <label htmlFor={name} className="floatingLabel">{floatingLabel}</label>
          </Form.Floating>)
          : (
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              placeholder={placeholder}
              name={name}
              id={name}
              as="input"
              ref={(r) => {
                if (refCallback) refCallback(r);
              }}
              className={classNames(className, errors && errors[name] ? 'is-invalid' : '')}
              // isInvalid={errors && errors[name] ? true : false}
              {...(register ? register(name) : {})}
              autoComplete={name}
              {...otherProps}
            />
          )
        }
        {hidePasswordEye ?
          null : < div
            className={classNames('input-group-text', 'input-group-password', {
              'show-password': showPassword,
            })}
            data-password={showPassword ? 'true' : 'false'}>
            <span
              className="password-eye"
              onClick={() => {
                setShowPassword(!showPassword);
              }}></span>
          </div>
        }
      </InputGroup >
    </>
  );
};

const MaskedFormInput = ({ name, mask, placeholder, refCallback, errors, register, className, floatingLabel, ...otherProps }) => {
  return (
    <>
      {floatingLabel ? (
        <Form.Floating>
          <MaskedInput
            type="text"
            name={name}
            id={name}
            ref={(r) => {
              if (refCallback) refCallback(r);
            }}
            mask={mask}
            keepCharPositions={true}
            placeholder={placeholder}
            // isInvalid={errors && errors[name] ? true : false}
            {...(register ? register(name) : {})}
            className={classNames('form-control', className, errors && errors[name] ? 'is-invalid' : '')}
            autoComplete={name}
            {...otherProps}
          />
          <label htmlFor={name} className="floatingLabel">{floatingLabel}</label>
        </Form.Floating>
      ) : (
        <MaskedInput
          type="text"
          name={name}
          id={name}
          ref={(r) => {
            if (refCallback) refCallback(r);
          }}
          mask={mask}
          keepCharPositions={true}
          placeholder={placeholder}
          // isInvalid={errors && errors[name] ? true : false}
          {...(register ? register(name) : {})}
          className={classNames('form-control', className, errors && errors[name] ? 'is-invalid' : '')}
          autoComplete={name}
          {...otherProps}
        />
      )}
    </>
  );
};

type FormInputProps = {
  label?: string,
  floatingLabel?: string,
  type?: string,
  name?: string,
  placeholder?: string,
  register?: any,
  errors?: any,
  className?: string,
  labelClassName?: string,
  containerClass?: string,
  containerStyle?: string,
  refCallback?: any,
  children?: any,
  mask?: Array;
};

const FormInput = ({
  label,
  floatingLabel,
  type,
  name,
  placeholder,
  register,
  errors,
  className,
  labelClassName,
  containerClass,
  containerStyle,
  refCallback,
  children,
  mask,
  hidePasswordEye,
  ...otherProps
}: FormInputProps): React$Element<React$FragmentType> => {
  // handle input type
  const comp = type === 'textarea' ? 'textarea' : (type === 'select' || type === 'dropdown') ? 'select' : 'input';
  return (
    <>
      {type === 'hidden' ? (
        <input type={type} name={name} {...(register ? register(name) : {})} {...otherProps} />
      ) : (
        <>
          {type === 'checkbox' || type === 'radio' ? (
            <>
              <Form.Group className={containerClass} style={{ ...containerStyle }}>
                <Form.Check
                  type={type}
                  label={label}
                  name={name}
                  id={name}
                  ref={(r) => {
                    if (refCallback) refCallback(r);
                  }}
                  className={classNames(className, errors && errors[name] ? 'is-invalid' : '')}
                  // isInvalid={errors && errors[name] ? true : false}
                  {...(register ? register(name) : {})}
                  {...otherProps}
                />

                {errors && errors[name] ? (
                  <Form.Control.Feedback type="invalid">
                    {errors[name]['message']}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </>
          ) : (
            <Form.Group className={containerClass} style={{ ...containerStyle }}>
              {label ? <Form.Label className={labelClassName}>{label}</Form.Label> : null}
                  {type === 'password' ? (
                    <>
                      <PasswordInput
                        name={name}
                        placeholder={placeholder}
                        refCallback={refCallback}
                        errors={errors}
                        register={register}
                        className={className}
                        floatingLabel={floatingLabel}
                        hidePasswordEye={hidePasswordEye}
                        {...otherProps}
                      />
                    </>
                  ) : (
                    <>
                      {type === 'masked' ? (
                        <MaskedFormInput
                          name={name}
                          mask={mask}
                          placeholder={placeholder}
                          refCallback={refCallback}
                          errors={errors}
                          register={register}
                          className={className}
                          floatingLabel={floatingLabel}
                          {...otherProps}
                        />
                      ) : (
                            <>
                              {
                                floatingLabel ? (
                                  <Form.Floating>
                                    <Form.Control
                                      type={type}
                                      placeholder={placeholder}
                                      name={name}
                                      id={name}
                                      as={comp}
                                      ref={(r) => {
                                        if (refCallback) refCallback(r);
                                      }}
                                      className={classNames(className, errors && errors[name] ? 'is-invalid' : '')}
                                      // isInvalid={errors && errors[name] ? true : false}
                                      {...(register ? register(name) : {})}
                                      {...otherProps}
                                      autoComplete={name}>
                                      {children ? children : null}
                                    </Form.Control>
                                    <label htmlFor={name} className="floatingLabel">{floatingLabel}</label>
                                  </Form.Floating>
                                ) : (
                                  <Form.Control
                                    type={type}
                                    placeholder={placeholder}
                                    name={name}
                                    id={name}
                                    as={comp}
                                    ref={(r) => {
                                      if (refCallback) refCallback(r);
                                    }}
                                    // className={className}
                                    className={classNames(className, errors && errors[name] ? 'is-invalid' : '')}
                                    // isInvalid={errors && errors[name] ? true : false}
                                    {...(register ? register(name) : {})}
                                    {...otherProps}
                                    autoComplete={name}>
                                    {children ? children : null}
                                  </Form.Control>
                                )}
                            </>
                      )}
                    </>
                  )}

              {errors && errors[name] ? (
                <Form.Control.Feedback type="invalid">
                  {errors[name]['message']}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          )
          }
        </>
      )}
    </>
  );
};

export default FormInput;
