import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import questions from './Questions';
import OrderSummary from './OrderSummary';
import './Quiz.css';
import getComponent from './getComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import EmailBody from '../components/EmailBody';
import config from '../config';

const Quiz = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState(OrderSummary);
  const [validateStep, setValidateStep] = useState(null);
  const [errors, setErrors] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [questionsArray, setQuestions] = useState(questions);
  const currentQuestions = questionsArray.filter((q) => q.step === currentStep);

  const setSpecificError = (id, errorMessage) => {
    setErrors(prevErrors => ({ ...prevErrors, [id]: errorMessage }));
  };
  /* Order Summary items */
  const name = answers?.find(item => item.id === 'input_name')?.value;
  const date = answers?.find(item => item.id === 'input_date')?.value;
  const time = answers?.find(item => item.id === 'input_simcha_time')?.value;
  const shacharisTime = answers?.find(item => item.id === 'input_shachris_time')?.value;
  const address = answers?.find(item => item.id === 'input_address')?.value;
  const daveningLocation = answers?.find(item => item.id === 'input_davening_location')?.value;
  const guestNumber = answers?.find(item => item.id === 'input_guest_number')?.value;
  const tableNumber = answers?.find(item => item.id === 'input_table_number')?.value;
  const tableclothColor = answers?.find(item => item.id === 'input_tablecloth_color')?.value;
  const mechitza = answers?.find(item => item.id === 'input_mechitza')?.value;
  const headTable = answers?.find(item => item.id === 'input_head_table')?.value;
  const settingsNumber = answers?.find(item => item.id === 'input_settings_number')?.value;
  const upsellBig = answers?.find(item => item.id === 'input_upsell_big')?.value;
  const upsellSmall = answers?.find(item => item.id === 'input_upsell_small')?.value;
  const upsellBigArray = typeof upsellBig === 'string' && upsellBig.includes(',')
    ? upsellBig.split(',')
    : [upsellBig];

  const upsellSmallArray = typeof upsellSmall === 'string' && upsellSmall.includes(',')
    ? upsellSmall.split(',')
    : [upsellSmall];

  const onChange = (id, value) => {
    setAnswers(prevOrderSummary => {
      const updatedOrderSummary = prevOrderSummary.map(item => {
        if (item.id === id) {
          if (typeof value === 'object') {
            return { ...item, value: { ...item.value, ...value } };
          } else {
            const trimmedValue = typeof value === 'string' ? value?.trim() : value;
            return { ...item, value: trimmedValue };
          }
        }
        return item;
      });
      return updatedOrderSummary;
    });
    if (id === 'input_head_table' && value === 'No') {
      setQuestions(prevQuestions => {
        const updatedQuestionsArray = prevQuestions.map(item => {
          if (item.id === 'input_settings_number') {
            return { ...item, isrequired: false };
          }
          return item;
        });

        return updatedQuestionsArray;

      });
    } else if (id === 'input_head_table' && value === 'Yes') {
      setQuestions(prevQuestions => {
        const updatedQuestionsArray = prevQuestions.map(item => {
          if (item.id === 'input_settings_number') {
            return { ...item, isrequired: true };
          }
          return item;
        });

        return updatedQuestionsArray;

      });
    }

    validateInput(id, value);
  };


  const updateHiddenQuestions = (id, setHidden) => {
    setQuestions(prevQuestions => {
      const updatedQuestionsArray = prevQuestions.map(item => {
        if (id === 'input_name2' || id === 'input_email2' || id === 'input_phone2') {
          if (setHidden === undefined) {
            if (item.id === 'input_name2' || item.id === 'input_email2' || item.id === 'input_phone2') {
              return { ...item, isHidden: false };
            }
          } else if (setHidden === true) {
            if (item.id === 'input_name2' || item.id === 'input_email2' || item.id === 'input_phone2') {
              onChange('input_name2', '');
              onChange('input_email2', '');
              onChange('input_phone2', '');
              return { ...item, isHidden: true };
            }
          }
        }

        if (id === 'input_manager_number' || id === 'input_door_code') {
          if (setHidden === false) {
            if (item.id === 'input_manager_number' || item.id === 'input_door_code') {
              return { ...item, isHidden: false };
            }
          } else if (setHidden === true) {
            if (item.id === 'input_manager_number' || item.id === 'input_door_code') {
              onChange('input_manager_number', '');
              onChange('input_door_code', '');
              return { ...item, isHidden: true };
            }
          }
        }

        return item;
      });

      return updatedQuestionsArray;
    });
  };

  useEffect(() => {
    // Find number of guests in answers
    const guestNumber = answers.find(item => item.id === 'input_guest_number');
    if (guestNumber?.value.length > 0) {
      // Find price per guest
      const guestNumberQ = questions.find(q => q.id === guestNumber?.id);
      if (guestNumberQ) {
        const option = guestNumberQ.options.find(option => option.label === guestNumber.value);
        if (option) {
          const guestNumberPrice = option.price;
          const guestNumberQty = option.qty;

          // Check for upsells
          const upsellBig = answers.find(item => item.id === 'input_upsell_big');
          let totalBig = 0;
          let totalBigFlat = 0;
          if (upsellBig?.value !== '') {
            const labels = upsellBig.value.split(',').map(label => label.trim()); // split the string of values into an array
            const upsellBigQ = questions.find(q => q.id === upsellBig.id);
            if (upsellBigQ) {
              const options = upsellBigQ.options;
              labels.forEach(label => {
                const option = options.find(option => option.label === label);
                if (option) {
                  // Check if the option.rate === 'flat' then
                  if (option.rate === 'flat') {
                    totalBigFlat += option.price;
                  } else {
                    totalBig += option.price;
                  }
                }
              });
            }
          }

          const upsellSmall = answers.find(item => item.id === 'input_upsell_small');
          let totalSmall = 0;
          let totalSmallFlat = 0;
          if (upsellSmall?.value !== '') {
            const labels = upsellSmall.value.split(',').map(label => label.trim());
            const upsellSmallQ = questions.find(q => q.id === upsellSmall.id);
            if (upsellSmallQ) {
              const options = upsellSmallQ.options;
              labels.forEach(label => {
                const option = options.find(option => option.label === label);
                if (option) {
                  if (option.rate === 'flat') {
                    totalSmallFlat += option.price;
                  } else {
                    totalSmall += option.price;
                  }
                }
              });
            }
          }
          setTotalPrice(guestNumberPrice + (totalBig > 0 ? totalBig * guestNumberQty : 0) + (totalSmall > 0 ? totalSmall * guestNumberQty : 0) + totalBigFlat + totalSmallFlat);
        }
      }
    }
  }, [answers, questions, setTotalPrice]);
  /* TODO: add second contact email, check different emails, email from havajava*/
  const onSubmit = () => {
    const email = answers.find(item => item.id === 'input_email').value;
    const email2 = answers.find(item => item.id === 'input_email2').value;
    const body = EmailBody(answers, totalPrice);
    const emailArray = [];
    // console.log('body :>> ', body);
    if (email2 !== '') {
      emailArray.push(email.toString(), email2.toString());
    } else {
      emailArray.push(email.toString());
    }
    const payload = {
      replyTo: config.email,
      email: emailArray,
      subject: 'Pending Bris - Wait for our confirmation - Hava Java',
      message: body.toString()
    };

    fetch('https://email-server.evelt.app/sendemail/havajava', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
      })
      .catch((error) => {
      });
  };

  const checkDefault = (step) => {
    const questionsInStep = questions.filter(q => q.step === step);
    questionsInStep.forEach(question => {
      if (!question.value?.length && question.default) {
        onChange(question.id, question.default);
      }
    });
  };

  const validateInput = (id, value) => {
    const question = questions.find(q => q.id === id);

    const hasEmptyString = obj => {
      return Object.values(obj).some(val => typeof val === 'string' && !val.trim());
    };

    const isValueEmpty = typeof value === 'string' ? !value.trim() : typeof value === 'object' && hasEmptyString(value);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    let errorMessage = '';

    if (question.isrequired && isValueEmpty) {
      errorMessage = 'This field is required';
    } else if (id === 'input_email' && !emailRegex.test(value)) {
      errorMessage = 'Invalid email format';
    }

    if (id === 'input_settings_number') {
      const headTableAnswer = answers.find(a => a.id === 'input_head_table')?.value;
      if (headTableAnswer === 'Yes' && isValueEmpty) {
        errorMessage = 'Must choose number of settings for head table';
      } else if (headTableAnswer === 'No') {
        errorMessage = '';
      }
    }

    setErrors(prevErrors => {
      if (errorMessage) {
        return {
          ...prevErrors,
          [id]: errorMessage
        };
      } else {
        const { [id]: _, ...rest } = prevErrors;
        return rest;
      }
    });
  };



  useEffect(() => {
    if (validateStep) {
      const stepHasErrors = currentQuestions.some((question) => errors[question.id]);

      if (!stepHasErrors) {
        if (validateStep === 'next') {
          setCurrentStep((prevStep) => prevStep + 1);
        } else if (validateStep === 'submit') {
          onSubmit();
          setCurrentStep((prevStep) => prevStep + 1);
        }
      }
      setValidateStep(null);

    }
  }, [validateStep, errors, currentQuestions]);

  /* remove empty string errors */
  useEffect(() => {
    setErrors(prevErrors => {
      const cleanedErrors = Object.entries(prevErrors)
        .filter(([, value]) => value !== '')
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
      return cleanedErrors;
    });
  }, [answers]);

  // console.log('answers :>> ', answers);

  const inputPaymentAnswer = answers.find(a => a.id === 'input_payment');
  const isInputPaymentValid = inputPaymentAnswer && typeof inputPaymentAnswer.value === 'object' && Object.keys(inputPaymentAnswer.value).length > 0;

  return (
    <div className='grid two-columns full-height'>
      <div className="form-container">
        <div className="form-content">
          {currentQuestions.map((question) => {
            if (question.id === 'input_upsell_big' ||
              question.id === 'input_upsell_small' ||
              question.id === 'input_name' ||
              question.id === 'input_phone' ||
              question.id === 'input_email' ||
              question.id === 'input_name2' ||
              question.id === 'input_phone2' ||
              question.id === 'input_email2'
            ) {
              return null;
            }
            return (
              <div key={question.id}>
                {getComponent(question, onChange, errors[question.id], setSpecificError, answers, updateHiddenQuestions)}
              </div>
            );
          })}
          <div className="side-by-side">
            <div>
              {currentQuestions.map((question) => {
                if (question.id === 'input_name' ||
                  question.id === 'input_phone' ||
                  question.id === 'input_email') {
                  return (
                    <div key={question.id} /* className="grid two-columns-equal" */>
                      {getComponent(question, onChange, errors[question.id], setSpecificError, answers, updateHiddenQuestions)}
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <div>
              {currentQuestions.map((question) => {
                if (question.id === 'input_name2' ||
                  question.id === 'input_phone2' ||
                  question.id === 'input_email2') {
                  return (
                    <div key={question.id} /* className="grid two-columns-equal" */>
                      {getComponent(question, onChange, errors[question.id], setSpecificError, answers, updateHiddenQuestions)}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
          <div className="side-by-side">
            {currentQuestions.map((question) => {
              if (question.id === 'input_upsell_big') {
                return (
                  <div key={question.id} className="grid two-columns-equal with-scrollbar">
                    {getComponent(question, onChange, errors[question.id], setSpecificError, answers, updateHiddenQuestions)}
                  </div>
                );
              }
              return null;
            })}
            {currentQuestions.map((question) => {
              if (question.id === 'input_upsell_small') {
                return (
                  <div key={question.id} className="grid two-columns-equal">
                    {getComponent(question, onChange, errors[question.id], setSpecificError, answers, updateHiddenQuestions)}
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>

      </div>
      <div className="form-sidebar">
        <div className="order-summary">
          <h3>Order Summary</h3>
          {/* Name and Date */}
          <div className="order-summary-item">
            <span className="order-value">{`${name ?? null} ${date ?? null}`}</span>
          </div>

          {/* Guest Number */}
          {guestNumber && <div className="order-summary-item">
            <span className="order-label"><strong>Guests Attending: </strong></span>
            <span className="order-value">{guestNumber}</span>
          </div>}

          {/* Address */}
          {address && <div className="order-summary-item">
            <span className="order-value">{address}</span>
          </div>}

          {/* Tables Amount and Tablecloth Color */}
          {tableNumber && <div className="order-summary-item">
            <span className="order-value">{`Tables Amount: ${tableNumber} - ${tableclothColor ?? null}`}</span>
          </div>}

          {/* Mechitza */}
          {mechitza === 'Yes' && (
            <div className="order-summary-item">
              <span className="order-value">Mechitza</span>
            </div>
          )}

          {/* Head Table and Settings Number */}
          {headTable === 'Yes' && (
            <div className="order-summary-item">
              <span className="order-value">{`Head Table - ${settingsNumber ?? null}`}</span>
            </div>
          )}

          {/* Simcha Time */}
          {time && <div className="order-summary-item">
            <span className="order-label"><strong>Time: </strong></span>
            <span className="order-value">{time}</span>
          </div>}

          {/* Shachris Time */}
          {shacharisTime && <div className="order-summary-item">
            <span className="order-label"><strong>Shachris Time: </strong></span>
            <span className="order-value">{shacharisTime}</span>
          </div>}

          {/* Davening Location */}
          {daveningLocation && <div className="order-summary-item">
            <span className="order-value">{daveningLocation}</span>
          </div>}

          {/* UpsellBig */}
          {upsellBig.length > 0 && (
            <div className="order-summary-item">
              <span className="order-label"><strong>Enhancements: </strong></span>
              <ul className="order-values">
                {upsellBigArray.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}

          {/* UpsellSmall */}
          {upsellSmall.length > 0 && (
            <div className="order-summary-item">
              <span className="order-label"><strong>Additions: </strong></span>
              <ul className="order-values">
                {upsellSmallArray.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="total-container">
          <h4>Order Total: ${totalPrice}</h4>
          {currentStep === 7 && Object.keys(errors).length === 0 && isInputPaymentValid &&
            <button
              className='button'
              onClick={() => {
                checkDefault(currentStep);
                currentQuestions.forEach((question) => {
                  validateInput(question.id, answers.find(a => a.id === question.id)?.value);
                });
                setValidateStep('submit');
                onSubmit();
              }}
            >
              Submit
            </button>
          }
        </div>
      </div>
      <div className='button-container'>
        <div className='side-by-side'>
          <div>
            {currentStep > 0 && currentStep < 8 &&
              <button
                className='button'
                onClick={() => setCurrentStep((prevStep) => prevStep - 1)}
              >
                Previous
              </button>
            }
          </div>
          {currentStep < 7 && <button
            className='button'
            onClick={() => {
              checkDefault(currentStep);
              currentQuestions.forEach((question) => {
                validateInput(question.id, answers.find(a => a.id === question.id)?.value);

              });
              setValidateStep('next');
            }}
          >
            Next
          </button>}
        </div>
      </div>
    </div>
  );
};

export default Quiz;
