import React, { useEffect, useState } from 'react';
import '../Quiz/Quiz.css';

export const EmailInput = ({ id, label, isrequired, onChange, error, setErrors, answers, hidden, setHidden }) => {
  const orderSummaryItem = answers.find(item => item.id === id);
  const initialValue = orderSummaryItem ? orderSummaryItem.value : '';
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(id, newValue);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleBlur = (e) => {
    const email = e.target.value;
    if (isrequired && !email.trim()) {
      setErrors(id, 'Email is required');
    } else if (email && !validateEmail(email)) {
      setErrors(id, 'Invalid email format');
    } else {
      setErrors(id, '');
      onChange(id, email);
    }
  };

  useEffect(() => {
    if (hidden) {
      onChange(id, '');
      setValue('');
    }
  }, [hidden]);

  return (
    <div>

      {!hidden ? (
        <>
          <label>{label}</label>
          <br />
          <input
            type="email"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            isrequired={isrequired}
            className='form-control'
          />
          {error && <div style={{ color: 'red' }}>{error}</div>}
        </>) : (null)}
      <br />
      {id === 'input_email2' && !hidden &&
        <label
          onClick={e => { setHidden(id, !hidden); }}
          role="button"
          style={{ cursor: 'pointer' }}
        >
          <i className='mdi mdi-minus-circle-outline' />
          cancel
        </label>
      }
    </div>
  );
};