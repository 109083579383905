import React from 'react';
import './Quiz.css';


const OrderSummary = [
  {
    id: 'input_name',
    label: 'Name',
    value: ''
  },
  {
    id: 'input_phone',
    label: 'Phone number',
    value: ''

  },
  {
    id: 'input_email',
    label: 'Email',
    value: ''
  },
  {
    id: 'input_name2',
    label: 'Second contact full name',
    value: ''
  },
  {
    id: 'input_phone2',
    label: 'Second contact phone number',
    value: ''
  },
  {
    id: 'input_email2',
    label: 'Second contact email',
    value: ''
  },
  {
    id: 'input_date',
    label: 'Date of Simcha',
    value: ''

  },
  {
    id: 'input_guest_number',
    label: 'Guests Attending',
    value: ''

  },
  {
    id: 'input_ladies_number',
    label: 'Expected Ladies',
    value: ''

  },
  {
    id: 'input_men_number',
    label: 'Expected Men',
    value: ''

  },
  {
    id: 'input_address',
    label: 'Address',
    value: ''
  },
  {
    id: 'input_manager_number',
    label: 'Hall manager\'s number',
    value: ''
  },
  {
    id: 'input_door_code',
    label: 'Code',
    value: ''
  },
  {
    id: 'input_table_number',
    label: 'Tables',
    value: ''
  },
  {
    id: 'input_tablecloth_color',
    label: 'Tablecloth color',
    value: ''
  },
  {
    id: 'input_mechitza',
    label: 'Mechitza',
    value: ''
  },
  {
    id: 'input_head_table',
    label: 'Head table',
    value: ''
  },
  {
    id: 'input_settings_number',
    label: 'Settings',
    value: ''
  },
  {
    id: 'input_note',
    label: 'Settings Note',
    value: ''
  },
  {
    id: 'input_simcha_time',
    label: 'Simcha Time',
    value: ''
  },
  {
    id: 'input_shachris_time',
    label: 'Shachris',
    value: ''
  },
  {
    id: 'input_davening_location',
    label: '',
    value: ''
  },
  {
    id: 'input_upsell_big',
    label: 'HJ Stations',
    value: ''
  },
  {
    id: 'input_upsell_small',
    label: 'Platters',
    value: ''
  },
  {
    id: 'input_additional_info',
    label: 'Additonal Information',
    value: ''
  },
  {
    id: 'input_payment',
    label: 'Payment Information',
    value: {
   /*    cardName: '',
      brand: 'nocard',
      cardNumber: '',
      cvv: '',
      expMonth: 1,
      expYear: new Date().getFullYear().toString(),
      billingAddress: '',
      zipCode: '', */
    }
  },
];

export default OrderSummary;
