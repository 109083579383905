import React, { useEffect, useMemo, useRef, useState } from 'react';
import '../Quiz/Quiz.css';

export const RadioCheck = ({ id, label, isrequired, onChange, error, setErrors, answers, options, defaultOption }) => {
  const orderSummaryItem = answers.find(item => item.id === id);
  const initialValue = orderSummaryItem ? orderSummaryItem.value : '';
  const [value, setValue] = useState(initialValue || defaultOption);
  const [warning, setWarning] = useState('');

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (id === 'input_head_table' && newValue === 'No') {
      onChange('input_settings_number', '');
    }
    setValue(newValue);
    onChange(id, newValue);
  };

  const handleBlur = (e) => {
    const option = e.target.value;
    if (isrequired && !option.trim()) {
      setErrors(id, 'Field is required');
    } else {
      setErrors(id, '');
      onChange(id, option);
    }
  };
  return (
    <div>
      <br />
      <label>{label}</label>
      <br />
      <div style={{ display: 'flex' }}>
        {options.map((option, index) => (
          <div key={index} style={{ marginRight: '10px' }}>
            <input
              type="radio"
              id={`${id}-${index}`}
              name={id}
              value={option}
              checked={value === option}
              onChange={handleChange}
              onBlur={handleBlur}
              isrequired={isrequired}
            />
            {' '}
            <label htmlFor={`${id}-${index}`}>{option}</label>
          </div>
        ))}
      </div>
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};