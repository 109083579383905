import React, { useState, useEffect } from 'react';
import '../Quiz/Quiz.css';

export const TimeDropdown = ({ id, label, isrequired, onChange, error, setErrors, answers, defaultTime }) => {
  const orderSummaryItem = answers.find(item => item.id === id);
  const initialValue = orderSummaryItem && orderSummaryItem.value !== '' ? orderSummaryItem.value : defaultTime;
  const [value, setValue] = useState(initialValue);

  const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM)$/i;

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);

    if (timeRegex.test(newValue)) {
      onChange(id, newValue.toUpperCase());
      setErrors(id, '');
    } else {
      setErrors(id, 'Invalid time format. Please use h:mm AM/PM.');
    }
  };

  return (
    <div>
      <label>{label}</label>
      <br />
      <input
        type="text"
        value={value}
        onChange={handleChange}
        required={isrequired}
        className='form-control'
      />

      {error && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};

export default TimeDropdown;
