import React from 'react';
import './Quiz.css';

export const questions = [
  {//TODO: add second contact info
    id: 'input_name',
    step: 0,
    type: 'text',
    label: 'Enter your full name',
    isrequired: true,
    isHidden: false

  },
  {
    id: 'input_phone',
    step: 0,
    type: 'phone',
    label: 'Enter your phone number',
    isrequired: true,
    isHidden: false
  },
  {
    id: 'input_email',
    step: 0,
    type: 'email',
    label: 'Enter your email',
    isrequired: true,
    isHidden: false
  },
  {
    id: 'input_name2',
    step: 0,
    type: 'text',
    label: 'Enter second contact full name',
    isrequired: false,
    isHidden: true
  },
  {
    id: 'input_phone2',
    step: 0,
    type: 'phone',
    label: 'Enter second contact phone number',
    isrequired: false,
    isHidden: true


  }, {
    id: 'input_email2',
    step: 0,
    type: 'email',
    label: 'Enter second contact email',
    isrequired: false,
    isHidden: true

  },
  {
    id: 'input_date',
    step: 1,
    type: 'dateDropdown', // Displays warning if date is close to current and does not allow dates close to current
    label: 'When is the Bris',
    options: ['today [date]', 'tomorrow [date]', '[day of the week] two days from now + [date]', 'up to 8 days'], //TODO: add available dates dynamically today will not show
    isrequired: true,

  },
  /* TODO: put in next and previous button */
  {// TODO: add price
    id: 'input_guest_number',
    step: 2,
    type: 'dropdownPrice',
    label: 'How many guests',
    options: [
      { label: '80 people - $2800', price: 2800, qty: 80 },
      { label: '100 people - $3500', price: 3500, qty: 100 },
      { label: '120 people - $4200', price: 4200, qty: 120 },
      { label: '150 people - $5250', price: 5250, qty: 150 }
    ],
    isrequired: true,

  },
  {
    id: 'input_ladies_number',
    step: 2,
    type: 'number',
    label: 'Expected Ladies',
    isrequired: true,
  },
  {
    id: 'input_men_number',
    step: 2,
    type: 'number',
    label: 'Expected Men',
    isrequired: true,
  },
  { //TODO: google places input address, can use hidden fields for posting
    id: 'input_address',
    step: 3,
    type: 'address', // street, city, state, zip, country
    label: 'Please enter the address of the event', // make sure its within the area they serve
    isrequired: true,
  },
  {
    id: 'input_table_number',
    step: 4,
    type: 'number',
    label: 'How many tables total?',
    isrequired: true,
  },
  {
    id: 'input_tablecloth_color',
    step: 4,
    type: 'dropdown',
    label: 'Choose the color of the tablecloth',
    options: [
      'Teal Textured', //default
      'Sand Weave',
      'Silver and Gold Double-Sided',
      'Silver and Blue Double-Sided',
      'Gold Burst',
      'Grey Burst'
    ],
    isrequired: true,
    default: 'Teal Textured'
  },
  {
    id: 'input_mechitza',
    step: 4,
    type: 'radio', //radio input, toggle switch that shows yes or no
    label: 'Mechitza needed?',
    options: ['Yes', 'No'],
    isrequired: true,
  },
  {
    id: 'input_head_table',
    step: 4,
    type: 'radio', //radio input, toggle switch that shows yes or no
    label: 'Head table needed?',
    options: ['Yes', 'No'],
    isrequired: true,
  },
  { // TODO: check if head table is needed then ask how many settings
    id: 'input_settings_number',
    step: 4,
    type: 'dropdown',
    label: 'How many settings needed (up to 6) ?',
    options: [2, 3, 4, 5, 6],
    isrequired: true,
  },
  // {//TODO: should this be included?
  //   id: 'input_note',
  //   step: 4,
  //   type: 'note',
  //   label: '+ Add a note about tables and seating',// only if someone clicks on it show text field otherwise hide
  //   isrequired: false,
  // },
  {
    id: 'input_simcha_time',
    step: 5,
    type: 'time', //text field that only allows time
    label: 'Time of bris?',
    isrequired: true,
    default: '8:00 AM',
  },
  {
    id: 'input_shachris_time',
    step: 5,
    type: 'time', //text field that only allows time
    label: 'Shachris',
    isrequired: true,
    default: '8:00 AM',
  },
  {
    id: 'input_davening_location',
    step: 5,
    type: 'dropdown',
    label: 'Davening and Meal',
    options: [
      'Separate rooms',
      'Same room',
      'Davening off premises'
    ],
    default: 'Separate rooms',
    isrequired: true,
  },
  {
    id: 'input_manager_number',
    step: 5,
    type: 'phone',
    label: 'Please enter the hall manager\'s number',
    isrequired: false,
    isHidden: true,
  },
  {
    id: 'input_door_code',
    step: 5,
    type: 'text',
    label: 'Lock combination',
    isrequired: false,
    isHidden: true,
  },
  /* TODO: get list of upsell small options,
  will be grid style with image of each and a checkbox */
  {
    id: 'input_upsell_big',
    step: 6,
    type: 'upsell',
    label: 'Enhance your event with HJ Signature Stations',
    options: [
      {
        label: 'Omelette Station',
        /* img: './coffee-tea-station.jpg', */
        description: 'A Beautiful setup with fresh omelets made on-premises with a variety of different toppings.',
        price: 650,
        rate: 'flat'
      },
      {
        label: 'Espresso Bar',
        price: 650,
        rate: 'flat'
      },
      {
        label: 'Pasta Station',
        description: 'Have your guests build their own pasta dish by selecting from an assortment of pasta types, sauce, vegetable, and cheese toppings.',
        price: 750,
        rate: 'flat'
      },
      {
        label: 'Pancake / Waffle Station',
        description: 'Waffles & Pancakes are freshly made and personalized for each guest with an array of toppings.',
        price: 650,
        rate: 'flat'
      },
      {
        label: 'Ice Cream / Waffle Station',
        description: 'Waffles & Ice Cream are freshly made and personalized for each guest with an array of toppings.',
        price: 350,
        rate: 'flat'
      },
    ],
    isrequired: false,
  },
  {
    id: 'input_upsell_small',
    step: 6,
    // type: 'upsell', //TODO: change to a multiSelect dropdown
    type: 'upsellDropdown',
    label: 'Elevate your event with Platters',
    options: [
      {
        label: 'Cereal Bar',
        description: "Featuring a variety of four cereals to cater to everyone's preferences. Serves up to 100 guests.",
        price: 125,
        rate: 'flat'
      },
      {
        label: 'Pancake Assortment',
        description: "Our renowned pancakes. A highly sought-after upgrade serves 100 guests.",
        price: 250,
        rate: 'flat'
      },
      {
        label: 'Large Fresh Fruit Platter',
        description: "Beautifully arranged seasonal fruit assortment on a platter ready to serve. 1 Large Platter",
        price: 115,
        rate: 'flat'
      },
      {
        label: '2 Small Fresh Fruit Platters',
        description: "Beautifully arranged seasonal fruit assortment on a platter ready to serve. 2 Small Platters",
        price: 130,
        rate: 'flat'
      },
      {
        label: 'Carrot Cheese Log ',
        description: "Delicious dairy cream cheese frosting carrot cake log. It's a standout addition for an added touch. Whole cake",
        price: 95,
        rate: 'flat'
      },
      {
        label: 'Stuffed Cookie Platter',
        description: "Dairy cookies filled with surprise fillings. Red velvet cookies filled with cream cheese frosting and caramelized butter filled with dulce de leche.",
        price: 55,
        rate: 'flat'
      },
      {
        label: 'Petite Cheese Pastries',
        description: "This is a popular addition to upgrade your event. Assortment of cheese bites, florets, cheesecake cookies, truffles, and cheese bars (56 pcs total).",
        price: 150,
        rate: 'flat'
      },
      {
        label: 'Cheese blintz',
        description: "Thin crepes filled with cream cheese mixture and strawberry dipping sauce on the side. 9x13 (18 pcs).",
        price: 80,
        rate: 'flat'
      },
      {
        label: 'Signature Whole Cheesecake',
        description: "Elevate your event with this drool-worthy addition. Flavors available *Zebra, seasonal flavor.",
        price: 95,
        rate: 'flat'
      },
      {
        label: 'Whole Side of Salmon Platter',
        description: "wholesome protein-packed start to your day. Available in sweet chili & mango salsa or herbed salmon with grilled vegetables.",
        price: 150,
        rate: 'flat'
      },
    ],
    isrequired: false,
  },
  // {//Possible downloads, terms and conditions, etc
  /* TODO: download of what is included that will be sent along with the email
  download of terms and conditions
  is this needed? */
  //   id: 'input_additional_info',
  //   step: 8,
  //   type: 'additional_info',
  //   label: 'Additonal Information?',
  //   isrequired: false,
  // },
  {
    id: 'input_payment',
    step: 7,
    type: 'payment', // CC, first name, last name, cc number, exp, security code, address, city, state, zip, country or Zelle?
    label: 'Payment Information',
    isrequired: true,
  },
  {
    id: 'input_email',
    step: 8,
    type: 'emailBody',
  }
];

export default questions;
