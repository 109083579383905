import React, { useEffect, useState } from 'react';
import '../Quiz/Quiz.css';

export const TextInput = ({ id, label, isrequired, onChange, error, setErrors, answers, hidden, setHidden }) => {
  const orderSummaryItem = answers.find(item => item.id === id);
  const initialValue = orderSummaryItem ? orderSummaryItem.value : '';
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(id, newValue);
  };
  const handleBlur = (e) => {
    const text = e.target.value;
    if (isrequired && !text.trim()) {
      { orderSummaryItem.id === 'input_name' ? setErrors(id, 'Name is required') : setErrors(id, 'This field is required'); }
    } else {
      setErrors(id, '');
      onChange(id, text);
    }
  };

  useEffect(() => {
    if (hidden) {
      onChange(id, '');
      setValue('');
    }
  }, [hidden]);

  return (
    <div>

      {!hidden && (
        <>
          <label>{label}</label>
          <br />
          <input
            type="text"
            value={value}
            onChange={handleChange}
            required={isrequired}
            onBlur={handleBlur}
            className='form-control'
            maxLength={id === 'input_name' ? 30 : 100}
          />
          {error && <div style={{ color: 'red' }}>{error}</div>}
          {/* <br /> */}
        </>
      )}
      {id === 'input_name2' && hidden &&
        <label
          onClick={e => { setHidden(id); }}
          role="button"
          style={{ cursor: 'pointer' }}
        >
          <i className='mdi mdi-plus-circle-outline' /> Add contact</label>}
      {id === 'input_door_code' && !hidden &&
        <label
          onClick={e => { setHidden(id, !hidden); }}
          role="button"
          style={{ cursor: 'pointer' }}
        >
          <i className='mdi mdi-minus-circle-outline' /> cancel</label>}
    </div>
  );

};